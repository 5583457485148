































import Vue from 'vue'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      rules: {
        num: [{ required: true, message: '请填写激活码数量' }],
        price: [{ required: true, message: '请填写价格' }],
      },
      model: {
        num: '',
        price: '',
      },
      goods: [],
    }
  },
  created() {
    this.id && this.fetch()
  },
  methods: {
    async fetch() {
      this.formLoading = true
      const { data: configs } = await this.$http.get(`cart_codes/${this.id}`)
      this.model = Object.assign(this.model, configs)
      this.formLoading = false
    },
    async save() {
      ~(this.$refs.form as any).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true
          if (this.id) {
            await this.$http.put(`cart_codes/${this.id}`, this.model)
          } else {
            await this.$http.post('cart_codes', this.model)
          }
          this.loading = false
          this.$router.push('/cart_code/list')
          this.$message.success('保存成功')
        }
      })
    },
  },
})
